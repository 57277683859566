a {
	margin: 5px;
	color: #C50000;
}

p {
    margin: 0.25em;
	text-decoration: none;
}

body {
    margin: 0;
    background-color: #FFFDEF;
}

/* Section below contains the formatting for specific classNames*/
.PageTitle {
	display: flex;
	justify-content: center;
	align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
	color: #EB1D36;
	margin-top: 2vw;
}

.Portrait {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vw;
}

.ProjectTitle{
    display: flex;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #E70000;
    order: 0;
    margin-bottom: 20px;
}

.ProjectContent{
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #E70000;
    order: 1;
}

/* Section below contains the formatting for specific ids */
#kevin-img {
    height: 300px;
    width: auto;
    border-radius: 75%;
    object-fit:cover;
}

#home-page {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 40px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

#brief {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 30px;
    color: #E70000;
    display: flex;
    justify-content: center;
    align-items: center;
	text-align: center;
}

#links {
	font-size: 30px;
    color: #F1F1F1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

#project-holder {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1vw;
	width: auto;
	height: 100%;
}

#about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#about-me {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 25px;
    color: #000000;
    width: 75vw;
    
}